var calendar = document.getElementById("calendar");

if (calendar) {
    function generate_year_range(start, end) {
        var years = "";
        for (var year = start; year <= end; year++) {
            years += "<option value='" + year + "'>" + year + "</option>";
        }
        return years;
    }

    today = new Date();
    currentDayWeek = today.getDay();
    currentDay = today.getDate();
    currentMonth = today.getMonth();
    currentYear = today.getFullYear();
    selectYear = document.getElementById("year");
    selectMonth = document.getElementById("month");


    /*createYear = generate_year_range(1970, 2050);*/
    /** or*/
     createYear = generate_year_range( 2020, currentYear );
     

    document.getElementById("year").innerHTML = createYear;

    //var calendar = document.getElementById("calendar");
    var lang = calendar.getAttribute('data-lang');

    var months = "";
    var days = "";

    var monthDefault = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

    var dayDefault = ["D", "S", "T", "Q", "Q", "S", "S"];
    var weekdayDefault = ["Domingo", "Segunta", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];

    if (lang == "en") {
        months = monthDefault;
        days = dayDefault;
    } else if (lang == "id") {
        months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        days = ["Ming", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"];
    } else if (lang == "fr") {
        months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
        days = ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"];
    } else {
        months = monthDefault;
        days = dayDefault;
    }


    var $dataHead = "<tr>";
    for (dhead in days) {
        $dataHead += "<th data-days='" + days[dhead] + "'>" + days[dhead] + "</th>";
    }
    $dataHead += "</tr>";

    //alert($dataHead);
    document.getElementById("thead-month").innerHTML = $dataHead;


    monthAndYear = document.getElementById("monthAndYear");
    dayMonthAndYear = document.getElementById("dayMonthAndYear");
    showCalendar(currentMonth, currentYear, currentDayWeek);



    function next() {
        currentYear = (currentMonth === 11) ? currentYear + 1 : currentYear;
        currentMonth = (currentMonth + 1) % 12;
        showCalendar(currentMonth, currentYear);
        showListItems(mounth_format_func(currentMonth,currentYear));
    }

    function previous() {
        currentYear = (currentMonth === 0) ? currentYear - 1 : currentYear;
        currentMonth = (currentMonth === 0) ? 11 : currentMonth - 1;
        showCalendar(currentMonth, currentYear);
        showListItems(mounth_format_func(currentMonth,currentYear));
    }

    function jump() {
        currentYear = parseInt(selectYear.value);
        currentMonth = parseInt(selectMonth.value);
        showCalendar(currentMonth, currentYear);
    }

    function showCalendar(month, year, weekday) {

        var firstDay = ( new Date( year, month ) ).getDay();

        tbl = document.getElementById("calendar-body");

        
        tbl.innerHTML = "";

        
        /*monthAndYear.innerHTML = months[month] + " " + year;*/
        if (weekday) {
            dayMonthAndYear.innerHTML = weekdayDefault[weekday]+ ", " +currentDay+ " de " +months[month];    
        }
        
        selectYear.value = year;
        selectMonth.value = month;

        // creating all cells
        var date = 1;
        for ( var i = 0; i < 6; i++ ) {
            
            var row = document.createElement("tr");

            
            for ( var j = 0; j < 7; j++ ) {
                if ( i === 0 && j < firstDay ) {
                    cell = document.createElement( "td" );
                    cellText = document.createTextNode("");
                    cell.appendChild(cellText);
                    row.appendChild(cell);
                } else if (date > daysInMonth(month, year)) {
                    break;
                } else {
                    cell = document.createElement("td");
                    cell.setAttribute("data-date", date);
                    cell.setAttribute("data-month", month + 1);
                    cell.setAttribute("data-year", year);
                    cell.setAttribute("data-month_name", months[month]);
                    cell.className = "date-picker";
                    cell.innerHTML = "<span>" + date + "</span>";

                    if ( date === today.getDate() && year === today.getFullYear() && month === today.getMonth() ) {
                        cell.className = "date-picker selected";
                    }

                    let prettyDate = date_format_func(date, month, year);
                    /*console.log(eventDays)*/
                    /*.indexOf('jane') >= 0*/
                    if( eventDays.includes(prettyDate) ){
                        cell.className = "date-picker event-date";
                    }
                    row.appendChild(cell);
                    date++;
                }


            }

            tbl.appendChild(row);
        }

    }

    function daysInMonth(iMonth, iYear) {
        return 32 - new Date(iYear, iMonth, 32).getDate();
    }

    function date_format_func(day, month, year) {
        var formatMonth = ("0" +(month + 1)).slice(-2);
        var FormatDay = ("0" + day).slice(-2);
        return FormatDay+'/'+formatMonth+'/'+year;
    }

    function mounth_format_func(month, year) {
        var formatMonth = ("0" +(month + 1)).slice(-2);
        return formatMonth+'-'+year;
    }

    function showListItems(date){
        var listboxes = document.querySelectorAll('li[data-mounth]');
        if (listboxes.length > 0) {
            listboxes.forEach(box => {
                box.classList.add('d-none');
            });
        }
        let currentmounth = document.querySelectorAll('li[data-mounth="'+date+'"]');
        if (currentmounth.length > 0) {
            currentmounth.forEach(el => {
                el.classList.remove('d-none');
            });
        }
    }
}